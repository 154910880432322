/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Dispatch, useEffect } from 'react'

import Link from '../../atoms/Link'
import Text from '../../atoms/Text'
import Next from '../../atoms/svg/Next'
import Close from '../../atoms/svg/Close'

import { selectedMobileMenu } from '../../../types/components'

import { Item } from '../../../types'

type Menu = Item[];

export interface ILevel2 {
  setActiveMenu: Dispatch<boolean>,
  setPreviousMenu: Dispatch<string | null>,
  activeMenu?: selectedMobileMenu
  menu?: Menu
};

const Level1: React.FC<ILevel2> = ({ setActiveMenu, activeMenu, setPreviousMenu, menu }) => {

  const [className, setclassName] = useState('animate__slideInRight')
  const body = window.document.body;
  const classList = ['max-h-screen', 'overflow-hidden']

  const closeMenu = () => {
    setclassName('animate__slideOutRight')
    setTimeout(() => {
      setActiveMenu(false)
    }, 500);
  }

  useEffect(() => {
    setclassName('animate__slideInRight')
  }, [])

  const handleLinkClick = () => {
    body.classList.remove(...classList)
    setActiveMenu(false)
    setPreviousMenu(null)
  }

  return (
    <div className={`fixed z-30 right-0 bottom-0 top-0 left-8 bg-white shadow-left py-6 animate__animated ${className} animate__faster h-screen font-light lg:hidden`}>
      <div className="flex justify-between px-5 capitalize mb-4" onClick={closeMenu}>
        <Text type="P" ptype="p-base" className="text-lg mb-0 text-blue-stone">
          {activeMenu?.name}
        </Text>
        <Close />
      </div>
      <div className="flex justify-between items-center bg-blue-stone px-5 text-base text-white" onClick={closeMenu}>
        <Text type="P" ptype="p-base" className="py-2 white-text mb-0 ">
          Back
        </Text>
        <div>
          <Next />
        </div>
      </div>
      <div className="w-10/12 mx-auto">
        <div className="my-10">
          {menu?.map((menu, index) => (
            <Link type="internal" to={menu.navanchor ? `${menu?.link?.slug?.current}#${menu.navanchor}` : menu?.link?.slug?.current} className={`mb-3 border-b pb-1 border-white-smoke block ${menu.indent ? 'ml-2': ''}`} key={index} onClick={handleLinkClick}>{menu.name}</Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Level1