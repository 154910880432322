import React, { useState, useEffect } from 'react'
import Link from '../../atoms/Link';
import MobileMenuLevel1 from './MobileMenuLevel1'
import useProgressBar from '../../../hooks/useProgressBar'
import { useGlobalStateContext } from '../../../Context/app'
import CardinalLogoFull from '../../atoms/svg/CardinalLogoFull'
// import SearchGrey from '../../atoms/svg/SearchGrey'
import Hamburger from '../../atoms/svg/Hamburger'
import { replaceLinks } from '../../../utils/service'
import { Item } from '../../../types'
import MaxWidth from '../../molecules/Maxwidth'
import { BreadCrumb } from '../../../types/gatsby-node'

type Menu = Item[]

const Header: React.FC = () => {

  const [searchVisible] = useState(false)
  const [activeMobileMenu, setActiveMobileMenu] = useState<string | null>(null)
  const [activeMenu, setActiveMenu] = useState<string | null>(null)
  const [scrollPositionPercentage] = useProgressBar()
  const { pageContext } = useGlobalStateContext()

  const [menus, setMenu] = useState<Menu>()

  const [breadCrumbData, setBreadCrumb] = useState<BreadCrumb[]>()

  useEffect(() => {
    if (pageContext) {
      setMenu(pageContext?.navigation?.items);
      const breadcrumb = pageContext?.breadcrumb || [];
      const data = [...breadcrumb, { data: { title: pageContext?.breadcrumbTitle || '' } }];
      setBreadCrumb(data)
    }
    return () => {
      setMenu(undefined);
      setBreadCrumb(undefined)
    }
  }, [pageContext])

  return (
    <nav id="nav-id" className="sticky top-0 bg-white z-100">
      <header className="lg:block hidden">
        <div>
          <MaxWidth>
            <nav className="mx-auto py-9 flex justify-between items-center">
              <Link type="internal" to="/" className="py-2"><CardinalLogoFull /></Link>
              <ul className="flex flex-1 justify-between items-center xxl:mx-28 lg:mx-10 font-light text-sm space-x-3 lg:text-xs xl:text-sm">
                {menus?.map((menu, index) => (
                  <li className="border-solid border-b border-transparent hover:text-viking hover:border-viking py-2 relative" key={index}>
                    {menu?.externalUrl ?
                      <Link to={menu?.externalUrl} type="external" onMouseOver={() => setActiveMenu(menu.name)}>{menu.name}</Link>
                      : 
                      <Link to={replaceLinks(`/${menu?.link?.slug?.current}`)} onMouseOver={() => setActiveMenu(menu.name)}>{menu.name}</Link>
                    }
                    {menu.items?.length ? activeMenu === menu.name && (
                      <ul className={`absolute top-18 bg-white p-4 w-64 z-60 text-xs text-blue-stone border-t`} onMouseLeave={() => setActiveMenu(null)}>
                        {menu?.items?.map((submenu, index) => (
                          <li className={`border-solid border-b my-2 pb-1 hover:font-medium ${submenu.indent ? 'ml-2' : ''}`} key={index}><Link to={submenu.navanchor ? replaceLinks(`${submenu.link.slug.current}#${submenu.navanchor}`) : replaceLinks(submenu.link.slug.current)}>{submenu.name}</Link></li>
                        ))}
                      </ul>
                    ): ''}
                  </li>
                ))}
              </ul>
              {/* <button className="py-2" onClick={() => setSearchVisible(!searchVisible)}>
                {searchVisible ?
                  <SearchGrey fill="#4CC2B8" />
                : <SearchGrey />}

                </button> */}
            </nav>
          </MaxWidth>
        </div>
      </header>
      <header className="lg:hidden bg-white z-60">
        <div className="flex justify-between mx-3 py-5">
          <Link type="internal" to="/" className="py-2"><CardinalLogoFull /></Link>
          <button onClick={() => { setActiveMobileMenu('1') }}><Hamburger /></button>
        </div>
      </header>
        {activeMobileMenu && <MobileMenuLevel1 setActiveMenu={setActiveMobileMenu} menu={menus} />}
        {pageContext?.breadcrumbEnabled &&
            <div className={`py-2 bg-blue-stone z-50 lg:top-0`}>
              <MaxWidth className="w-9/12 mx-auto flex">
                {searchVisible ? (
                  <input type="search" className="w-full bg-transparent placeholder-white p-1 focus:outline-none focus:border-transparent text-white" placeholder="Search" autoFocus />
                ) : (
                    <p className="text-white text-xs font-medium">
                      {breadCrumbData?.map((page, index, array) =>
                        index < (array.length - 1) || array.length === 1 ? 
                          <Link type="internal" key={index} to={replaceLinks(`/${page?.data?.slug?.current || pageContext?.slug || '#'}`)} className="py-2">
                          {page?.data?.title?.trim().replace?.('Homepage', 'Home') || ''} {array.length > 1 ? ' | ': ''}
                          </Link> : <span className="font-light" key={index}>{page?.data?.title || ''}</span>
                      )}
                    </p>
                )}
              </MaxWidth>
            </div>
          }
        <div className={`bg-white-smoke h-1 z-20 lg:top-8`} id="scroll-bar">
          <div style={{ width: `${scrollPositionPercentage}%` }} className="bg-viking h-1"></div>
        </div>
    </nav>
  )
}

export default Header
