export const replaceLinks = (link:string) => {
  return link?.replace?.('//', '/') || '/'
}

export const paginate = (items: any[], current_page: number, per_page_items: number) => {
  let page = current_page || 1,
    per_page = per_page_items || 10,
    offset = (page - 1) * per_page,

    paginatedItems = items.slice(offset).slice(0, per_page_items),
    total_pages = Math.ceil(items.length / per_page),
    trimStart = (page - 1) * per_page, 
    trimEnd = (page * per_page) > items.length ? items.length : trimStart + per_page

  return {
    page: page,
    per_page: per_page,
    pre_page: page - 1 ? page - 1 : null,
    next_page: (total_pages > page) ? page + 1 : null,
    total: items.length,
    total_pages: total_pages,
    data: paginatedItems,
    index: `${++trimStart} - ${trimEnd} of ${items.length}`
  }
}
