import React from 'react'

export default function Close() {
  return (
    <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/close</title>
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g id="Mobile-menu" transform="translate(-1333.000000, -222.000000)" stroke="#12505F" strokeWidth="2">
          <g id="iPhone-mockup-1-Copy" transform="translate(1005.000000, 173.000000)">
            <g id="icon/close" transform="translate(329.000000, 50.000000)">
              <g transform="translate(0.307612, 0.307612)" id="Group-8">
                <line x1="-3.30761184" y1="9.19238816" x2="21.6923882" y2="9.19238816" id="Line-2" transform="translate(9.192388, 9.192388) rotate(45.000000) translate(-9.192388, -9.192388) "></line>
                <line x1="-3.30761184" y1="9.19238816" x2="21.6923882" y2="9.19238816" id="Line-2-Copy" transform="translate(9.192388, 9.192388) rotate(-45.000000) translate(-9.192388, -9.192388) "></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
