import React from 'react'

export default function CardinalLogoFull() {
  return (
    <svg width="152px" height="22px" viewBox="0 0 152 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Logo/Cardinal/full-color</title>
      <g id="-Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Top-navigation/Teal-breadcrumb" transform="translate(-165.000000, -45.000000)">
          <g id="Group" transform="translate(165.000000, 44.500000)">
            <g transform="translate(0.500000, 0.500000)">
              <path d="M7.884818,21.999903 C1.78935907,21.999903 0,20.0980422 0,14.8201604 L0,8.25335808 C0,2.97644606 1.78935907,1.07458528 7.884818,1.07458528 L22.2287858,1.07458528 L22.2287858,5.09361183 L8.09721347,5.09361183 C5.27691202,5.09361183 4.57862556,5.92185716 4.57862556,8.49969803 L4.57862556,14.6358903 C4.57862556,17.2437963 5.27691202,18.0119114 8.12727858,18.0119114 L22.2287858,18.0119114 L22.2287858,21.999903 L7.884818,21.999903 Z" id="Fill-1" fill="#404040"></path>
              <path d="M30.8106295,21.999903 C26.0797387,21.999903 24.8063357,20.3123774 24.8063357,17.6123364 L24.8063357,16.0479807 C24.8063357,13.2548349 25.9585084,11.6594443 30.5080387,11.6594443 L42.6688914,11.6594443 L42.6688914,11.291874 C42.6688914,9.17470828 41.8794398,8.62189816 39.8786551,8.62189816 L29.2937961,8.62189816 L29.2937961,5.37001688 L39.8786551,5.37001688 C45.1235623,5.37001688 47.1864169,7.27187766 47.1864169,11.5062092 L47.1864169,15.5562707 C47.1864169,20.3734775 45.519258,21.999903 39.6963247,21.999903 L30.8106295,21.999903 Z M42.6688914,14.4826553 L30.99199,14.4826553 C29.9309825,14.4826553 29.2937961,14.8511953 29.2937961,16.1701809 L29.2937961,17.3058662 C29.2937961,18.4716167 29.9920826,18.8702219 31.1442552,18.8702219 L39.6352247,18.8702219 C41.7582095,18.8702219 42.6688914,18.3794817 42.6688914,15.8947457 L42.6688914,14.4826553 Z" id="Fill-3" fill="#404040"></path>
              <path d="M50.4617684,21.999903 L50.4617684,12.3654895 C50.4617684,7.39504763 52.6157882,5.37001688 58.164256,5.37001688 L61.1067576,5.37001688 L61.1067576,9.08160341 L58.2854863,9.08160341 C55.7687455,9.08160341 54.9802637,9.75758351 54.9802637,12.3955546 L54.9802637,21.999903 L50.4617684,21.999903 Z" id="Fill-5" fill="#404040"></path>
              <path d="M71.1134936,21.999903 C64.9569346,21.999903 63.1675755,20.0980422 63.1675755,14.8201604 L63.1675755,12.5807945 C63.1675755,7.27187766 64.9569346,5.37001688 71.1134936,5.37001688 L80.2406796,5.37001688 L80.2406796,-2.75645764e-14 L84.7591749,-2.75645764e-14 L84.7591749,14.8201604 C84.7591749,20.0980422 82.9707857,21.999903 76.8442918,21.999903 L71.1134936,21.999903 Z M67.6860709,14.6969904 C67.6860709,17.6123364 68.4745527,18.1331417 71.6895799,18.1331417 L76.7230615,18.1331417 C79.5734281,18.1331417 80.2406796,17.4891664 80.2406796,14.7280254 L80.2406796,9.29690841 L71.6595148,9.29690841 C68.4745527,9.29690841 67.6860709,9.75758351 67.6860709,12.7340296 L67.6860709,14.6969904 Z" id="Fill-7" fill="#404040"></path>
              <polygon id="Fill-9" fill="#404040" points="88.2469219 22 92.8556125 22 92.8556125 5.39048056 88.2469219 5.39048056"></polygon>
              <path d="M113.113679,21.999903 L113.113679,12.7340296 C113.113679,9.97191866 112.355262,9.29690841 109.444765,9.29690841 L104.531544,9.29690841 C101.681178,9.29690841 100.862631,9.97191866 100.862631,12.7340296 L100.862631,21.999903 L96.3431655,21.999903 L96.3431655,12.5807945 C96.3431655,7.27187766 98.1325245,5.37001688 104.227983,5.37001688 L109.717291,5.37001688 C115.842815,5.37001688 117.632174,7.27187766 117.632174,12.5807945 L117.632174,21.999903 L113.113679,21.999903 Z" id="Fill-11" fill="#404040"></path>
              <path d="M126.72978,21.999903 C121.998889,21.999903 120.725486,20.3123774 120.725486,17.6123364 L120.725486,16.0479807 C120.725486,13.2548349 121.877659,11.6594443 126.427189,11.6594443 L138.588042,11.6594443 L138.588042,11.291874 C138.588042,9.17470828 137.79859,8.62189816 135.797806,8.62189816 L125.212947,8.62189816 L125.212947,5.37001688 L135.797806,5.37001688 C141.042713,5.37001688 143.105567,7.27187766 143.105567,11.5062092 L143.105567,15.5562707 C143.105567,20.3734775 141.438408,21.999903 135.615475,21.999903 L126.72978,21.999903 Z M138.588042,14.4826553 L126.91114,14.4826553 C125.850133,14.4826553 125.212947,14.8511953 125.212947,16.1701809 L125.212947,17.3058662 C125.212947,18.4716167 125.911233,18.8702219 127.063406,18.8702219 L135.554375,18.8702219 C137.67736,18.8702219 138.588042,18.3794817 138.588042,15.8947457 L138.588042,14.4826553 Z" id="Fill-12" fill="#404040"></path>
              <polygon id="Fill-13" fill="#404040" points="146.532505 22 151.141196 22 151.141196 9.69842312e-05 146.532505 9.69842312e-05"></polygon>
              <path d="M89.7934324,4.57183666 C88.6713248,4.57183666 88.2475038,4.14219652 88.2475038,3.15974625 L88.2475038,1.38008561 C88.2475038,0.39860519 88.6713248,0 89.7934324,0 L91.2802007,0 C92.4314035,0 92.8561944,0.39860519 92.8561944,1.38008561 L92.8561944,3.15974625 C92.8561944,4.14219652 92.4314035,4.57183666 91.2802007,4.57183666 L89.7934324,4.57183666 Z" id="Fill-14" fill="#4CC2B8"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
