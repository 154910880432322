import React from 'react'
import { TextProps } from '../../../../types/components'

export const H6: React.FC<TextProps & Record<string, any>> = ({ children, className = '', ...rest }) => {
  return (
    <h6 className={`leading-10 font-normal align-baseline font-montserrat mb-5 text-2xl ${className}`} {...rest}>
      {children}
    </h6>
  )
}

export default H6;