import React from 'react'
import { TextProps } from '../../../../types/components'


export const Index: React.FC<TextProps & Record<string,any>> = ({ className = '', children, ...rest }) => {
  return (
    <blockquote className={`text-3xl font-light text-viking leading-12 my-12 font-montserrat ${className}`} {...rest}>
      {children}
    </blockquote>
  )
}

export default Index;