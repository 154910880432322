import React from 'react'
import { TextProps } from '../../../../types/components'
export type PType = 'p-base' | 'sm-bold' | 'SM_LIGHT'

export const Index: React.FC<TextProps & Record<string, any>> = ({ ptype ="p-base", className = '', children, ...rest }) => {
  return (
    <p className={`${className} ${ptype}`} {...rest}>
      {children}
    </p>
  )
}

export default Index;
