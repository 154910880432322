import React from 'react'
import { TextProps } from '../../../../types/components'

export const Index: React.FC<TextProps & Record<string,any>> = ({ className = '', children, ...rest }) => {
  return (
    <h1 className={`text-4xl leading-11 align-baseline font-montserrat my-11 mt-0 ${className}`} {...rest}>
      {children}
    </h1>
  )
}

export default Index;