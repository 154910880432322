import React from 'react'
import { TextProps } from '../../../../types/components'

export const Index: React.FC<TextProps & Record<string,any>> = ({ children, className = '', ...rest }) => {
  return (
    <h5 className={`text-lg leading-7 font-semibold align-baseline font-montserrat ${className}`} {...rest}>
      {children}
    </h5>
  )
}

export default Index;