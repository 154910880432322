import React from 'react'
import { IText } from '../../../types/components'

import H1 from '../Typograph/H1'
import H2 from '../Typograph/H2'
import H3 from '../Typograph/H3'
import H4 from '../Typograph/H4'
import H5 from '../Typograph/H5'
import H6 from '../Typograph/H6'
import P from '../Typograph/P'
import Quote from '../Typograph/Quote'

export const Text: React.FC<IText & Record<string,any>> = ({
  type,
  ptype,
  children,
  ...rest 
}) => {

  const TextTypes = {
    'H1': H1,
    'H2': H2,
    'H3': H3,
    'H4': H4,
    'H5': H5,
    'H6': H6,
    'P': P,
    'Quote': Quote,
  }

  const Component = TextTypes[type] ? TextTypes[type] : TextTypes['P'];

  return <Component ptype={ptype} {...rest}>{children}</Component>;
}

export default Text;
