import React from 'react'
import { TextProps } from '../../../../types/components'


export const Index: React.FC<TextProps & Record<string,any>> = ({ children, className = '', ...rest }) => {
  return (
    <h3 className={`text-2xl leading-7 font-normal align-baseline font-montserrat ${className}`} {...rest}>
      {children}
    </h3>
  )
}

export default Index;