import React from 'react'
import { TextProps } from '../../../../types/components'

export const Index: React.FC<TextProps & Record<string,any>> = ({ children, className = '', ...rest }) => {
  return (
    <h2 className={`text-3xl leading-10 font-normal align-baseline font-montserrat my-7 ${className}`} {...rest}>
      {children}
    </h2>
  )
}

export default Index;