import React from 'react'

export default function Youtube() {
  return (
    <svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>YouTube icon</title>
      <g id="-Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Footer" transform="translate(-1110.000000, -223.000000)" fill="#4CC2B8">
          <g id="Group" transform="translate(1020.000000, 223.000000)">
            <g id="YouTube-icon" transform="translate(90.000000, 0.000000)">
              <g id="Group-5" stroke="#4CC2B8" strokeWidth="0.5">
                <path d="M14.2657,0.9338 C6.9137,0.9338 0.9337,6.9138 0.9337,14.2658 C0.9337,21.6168 6.9137,27.5978 14.2657,27.5978 C21.6167,27.5978 27.5977,21.6168 27.5977,14.2658 C27.5977,6.9138 21.6167,0.9338 14.2657,0.9338 Z M14.2657,28.2808 C6.5367,28.2808 0.2497,21.9938 0.2497,14.2658 C0.2497,6.5378 6.5367,0.2498 14.2657,0.2498 C21.9937,0.2498 28.2817,6.5378 28.2817,14.2658 C28.2817,21.9938 21.9937,28.2808 14.2657,28.2808 Z" id="Stroke-3"></path>
              </g>
              <path d="M20.332,16.534 L20.332,12.125 C20.332,12.125 20.332,10 18.207,10 L10.124,10 C10.124,10 8,10 8,12.125 L8,16.534 C8,16.534 8,18.659 10.124,18.659 L18.207,18.659 C18.207,18.659 20.332,18.659 20.332,16.534 L20.332,16.534 Z M16.561,14.336 L12.524,16.707 L12.524,11.964 L16.561,14.336 Z" id="Fill-5"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
