/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Dispatch, useEffect } from 'react'

import Link from '../../atoms/Link'
import MobileMenuLevel2 from './MobileMenuLevel2'
import Next from '../../atoms/svg/Next'
import Close from '../../atoms/svg/Close'

import { Item } from '../../../types'

type Menu = Item[];
export interface ILevel1 {
  setActiveMenu: Dispatch<string | null>
  menu?: Menu
};


const Level1: React.FC<ILevel1> = ({ setActiveMenu, menu }) => {

  const [selectedMenu, setselectedMenu] = useState<Item[]>()
  const [menuVisible, setMenuVisible] = useState(false)
  const [className, setclassName] = useState('animate__slideInRight')
  const body = window.document.body;
  const classList = ['max-h-screen', 'overflow-hidden']

  const closeMenu = () => {
    body.classList.remove(...classList)
    setclassName('animate__slideOutRight')
    setTimeout(() => {
      setActiveMenu(null)
    }, 500);
  }

  useEffect(() => {
    setclassName('animate__slideInRight')
    body.classList.add(...classList)
  }, [])


  const handleLinkClick = () => {
    body.classList.remove(...classList)
    setMenuVisible(false)
  }

  return (
    <div className={`fixed z-60 right-0 bottom-0 top-0 left-8 bg-white shadow-left py-6 animate__animated ${className} animate__faster h-screen lg:hidden`}>
      <div className="flex items-center justify-end w-full px-5 capitalize mb-2" onClick={closeMenu}>
        <Close />
      </div>
      <div className="w-9/12 mx-auto flex flex-col justify-between h-10/12 my-5">
        <div>
          {/* <div className="flex items-center border-viking border rounded-full pl-2">
            <input type="search" className="focus:outline-none focus:border-transparent flex-1 placeholder-gray-400 text-gray-400" placeholder="Search Cardinal" />
            <div className="bg-viking rounded-r-full text-center py-2 px-2"><SearchGrey fill="#ffffff" /></div>
          </div> */}
          <div className="my-10">
            {menu?.map((menu, index) => (
              menu.items?.length ? (
                <p className="mb-5 border-b pb-1 border-white-smoke flex items-center" key={index} onClick={() => {
                  setselectedMenu(menu.items)
                  setMenuVisible(true)
                }}> <span className="transform rotate-180 block"><Next fill="#4CC2B8" /></span> <span className="pl-4">{menu.name}</span></p>
              ) : (
                  <Link to={menu?.link?.slug?.current || ''} className="mb-5 border-b pb-1 border-white-smoke flex items-center" key={index} onClick={handleLinkClick}>
                    <span className="transform rotate-180 block"><Next fill="#4CC2B8" /></span> 
                    <span className="pl-4">{menu.name}</span>
                  </Link>
              )
            ))}
          </div>
        </div>
        <div>
          <Link type="internal" to="/" className="text-white bg-blue-stone py-1 block rounded-full text-center">Register/ Login</Link>
        </div>
      </div>
        
      {menuVisible && <MobileMenuLevel2 setActiveMenu={setMenuVisible} menu={selectedMenu} setPreviousMenu={setActiveMenu} />}
    </div>
  )
}

export default Level1