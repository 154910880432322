import React from 'react'
import { Link as GLink } from 'gatsby'
import { ILink } from '../../../types/components'

export const Link: React.FC<ILink & Record<string, any>> = ({ children, type="internal", to, ...rest }) => {

  const LinkTypes = {
    'internal': <GLink to={to} {...rest}>{children}</GLink>,
    'external': <a href={to} {...rest} target="_blank" rel="noreferrer">{children}</a>,
  }

  return LinkTypes[type] ? LinkTypes[type] : LinkTypes['internal'];
}

export default Link;
