import React from 'react'

export default function Linkedin() {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="29px" height="29px">
      <title>linkedin icon</title>
      <g id="_-Symbols" data-name="-Symbols">
        <g id="Footer">
          <g id="LinkedIn-logo">
            <path id="Fill-1" fill="#4cc2b8" fillRule="evenodd" d="M9.41,20.06h2.4V12.38H9.41Zm1.14-8.64h0a1.34,1.34,0,1,1,0-2.67,1.34,1.34,0,1,1,0,2.67ZM21.4,20.06H18.68v-4c0-1-.42-1.75-1.36-1.75a1.39,1.39,0,0,0-1.3.94,1.79,1.79,0,0,0,0,.63v4.16h-2.7s0-7,0-7.68H16v1.2a2.46,2.46,0,0,1,2.39-1.28c1.7,0,3,1.11,3,3.49Z" />
            <path id="Fill-3" fill="#4cc2b8" fillRule="evenodd" d="M15,29A14,14,0,1,1,29,15,14,14,0,0,1,15,29M15,1.68A13.34,13.34,0,1,0,28.35,15,13.35,13.35,0,0,0,15,1.68" />
            <path id="Fill-4" fill="#4cc2b8" fillRule="evenodd" d="M15,29A14,14,0,1,1,29,15,14,14,0,0,1,15,29M15,1.68A13.34,13.34,0,1,0,28.35,15,13.35,13.35,0,0,0,15,1.68" />
            <path id="Stroke-5" fill="none" stroke="#4cc2b8" strokeWidth="0.5" d="M15,29A14,14,0,1,1,29,15,14,14,0,0,1,15,29ZM15,1.68A13.34,13.34,0,1,0,28.35,15,13.35,13.35,0,0,0,15,1.68Z" />
            <path id="Fill-6" fill="#4cc2b8" fillRule="evenodd" d="M9.41,20.06h2.4V12.38H9.41Zm1.14-8.64h0a1.34,1.34,0,1,1,0-2.67,1.34,1.34,0,1,1,0,2.67ZM21.4,20.06H18.68v-4c0-1-.42-1.75-1.36-1.75a1.39,1.39,0,0,0-1.3.94,1.79,1.79,0,0,0,0,.63v4.16h-2.7s0-7,0-7.68H16v1.2a2.46,2.46,0,0,1,2.39-1.28c1.7,0,3,1.11,3,3.49Z" />
          </g>
        </g>
      </g>
    </svg>
  )
}
