import React from 'react'
import { TextProps } from '../../../../types/components'

export const Index: React.FC<TextProps & Record<string,any>> = ({ children, className = '', ...rest }) => {
  return (
    <h4 className={`text-xl leading-7 align-baseline font-montserrat ${className}`} {...rest}>
      {children}
    </h4>
  )
}

export default Index;