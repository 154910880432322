import React from 'react'

export default function Clock() {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/clock</title>
      <defs>
        <polygon id="path-1" points="0 30 30 30 30 0 0 0"></polygon>
      </defs>
      <g id="-Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Footer" transform="translate(-1020.000000, -161.000000)">
          <g id="Group" transform="translate(1020.000000, 161.000000)">
            <path d="M24.9508594,24.9508594 C22.2931055,27.6084961 18.7591406,29.072168 15,29.072168 C11.2408594,29.072168 7.70689453,27.6084961 5.04914063,24.9508594 C2.39144531,22.2931641 0.927832031,18.7591992 0.927832031,15 C0.927832031,11.2408008 2.39144531,7.70683594 5.04914063,5.04914063 C7.70689453,2.39150391 11.2408594,0.927832031 15,0.927832031 C18.7591406,0.927832031 22.2931055,2.39150391 24.9508594,5.04914063 C27.6085547,7.70683594 29.072168,11.2407422 29.072168,15 C29.072168,18.7592578 27.6085547,22.2931641 24.9508594,24.9508594 M25.6069336,4.393125 C22.7739844,1.56017578 19.0069922,0 15,0 C10.9930078,0 7.22601563,1.56017578 4.39306641,4.393125 C1.56017578,7.22601562 0,10.9930078 0,15 C0,19.0069922 1.56017578,22.7739844 4.39306641,25.606875 C7.22601563,28.4398242 10.9930078,30 15,30 C19.0069922,30 22.7739844,28.4398242 25.6069336,25.606875 C28.4398242,22.7739844 30,19.0069922 30,15 C30,10.9930078 28.4398242,7.22601562 25.6069336,4.393125" id="Fill-1" fill="#4CC2B8"></path>
            <path d="M23.1268945,22.8254297 C20.9866406,25.0587305 18.1004297,26.2886719 15,26.2886719 C13.4026758,26.2886719 11.859375,25.9613672 10.4130469,25.3160742 C9.01570312,24.6925781 7.77650391,23.8044727 6.72902344,22.6755469 C4.78306641,20.5873828 3.71132813,17.8614844 3.71132813,15 C3.71132813,12.1385156 4.78306641,9.41261719 6.72978516,7.32375 C7.77650391,6.19558594 9.01570312,5.30742188 10.4130469,4.68392578 C11.859375,4.03863281 13.4026758,3.71132813 15,3.71132813 C21.2245898,3.71132813 26.2886719,8.77541016 26.2886719,15 C26.2886719,17.9394727 25.1663672,20.7179297 23.1268945,22.8254297 M15,2.78349609 C13.271543,2.78349609 11.6010352,3.13787109 10.0348828,3.83666016 C8.52269531,4.51136719 7.18183594,5.47230469 6.05027344,6.69199219 C3.94365234,8.95236328 2.78349609,11.9029687 2.78349609,15 C2.78349609,18.0970313 3.94365234,21.0476367 6.04962891,23.3073047 C7.18195312,24.5276367 8.52275391,25.4886328 10.035,26.1633398 C11.6010352,26.8621289 13.2716016,27.2165039 15.0000586,27.2165039 C16.6894922,27.2165039 18.3250781,26.877832 19.8612891,26.2099805 C21.3481641,25.5635742 22.6722656,24.6408398 23.795332,23.4690234 C26.0014453,21.1891992 27.2165625,18.1815234 27.2165625,15 C27.2165625,8.26376953 21.7362305,2.78349609 15,2.78349609" id="Fill-3" fill="#4CC2B8"></path>
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-6"></g>
            <polygon id="Fill-5" fill="#4CC2B8" mask="url(#mask-2)" points="14.5360547 7.26802734 15.4638867 7.26802734 15.4638867 4.48453125 14.5360547 4.48453125"></polygon>
            <polygon id="Fill-7" fill="#4CC2B8" mask="url(#mask-2)" points="14.5360547 25.5154688 15.4638867 25.5154688 15.4638867 22.7319727 14.5360547 22.7319727"></polygon>
            <polygon id="Fill-8" fill="#4CC2B8" mask="url(#mask-2)" points="23.0412305 15.4638867 25.5154688 15.4638867 25.5154688 14.5360547 23.0412305 14.5360547"></polygon>
            <polygon id="Fill-9" fill="#4CC2B8" mask="url(#mask-2)" points="4.48453125 15.4638867 6.95876953 15.4638867 6.95876953 14.5360547 4.48453125 14.5360547"></polygon>
            <polygon id="Fill-10" fill="#4CC2B8" mask="url(#mask-2)" points="6.93967383 8.21420508 7.59574805 7.55813086 9.4515293 9.41391211 8.79545508 10.0699863"></polygon>
            <polygon id="Fill-11" fill="#4CC2B8" mask="url(#mask-2)" points="20.2398516 9.41421094 22.0956328 7.55842969 22.751707 8.21450391 20.8959258 10.0702852"></polygon>
            <polygon id="Fill-12" fill="#4CC2B8" mask="url(#mask-2)" points="20.238709 20.5856484 20.8947832 19.9295742 22.7505645 21.7853555 22.0944902 22.4414297"></polygon>
            <polygon id="Fill-13" fill="#4CC2B8" mask="url(#mask-2)" points="6.9408457 21.7855957 8.79662695 19.9298145 9.45270117 20.5858887 7.59691992 22.4416699"></polygon>
            <polygon id="Fill-14" fill="#4CC2B8" mask="url(#mask-2)" points="15.4639453 14.4986133 15.4639453 8.81443359 14.5360547 8.81443359 14.5360547 14.8828711 19.0018359 19.3486523 19.6579688 18.6925781"></polygon>
          </g>
        </g>
      </g>
    </svg>
  )
}
