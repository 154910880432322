import { useState, useEffect, useCallback } from 'react'

const useProgressBar = () => {
  const [scrollPositionPercentage, setScrollPositionPercentage] = useState(0);

  const calculateScrollDistance = useCallback(() => {
    const scrollTop = window.pageYOffset; // Scroll position
    const winHeight = window.innerHeight; // Window Height
    const docHeight = getDocHeight(); // Entire page height

    const totalDocScrollLength = docHeight - winHeight; // How much the page can be scrolled
    // setScrollPosition(scrollTop); // Update state var
    setScrollPositionPercentage(scrollTop / totalDocScrollLength * 100); // Update state var
  }, [])

  function getDocHeight() {
    return Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
  }

  
  useEffect(() => {
    const listenToScrollEvent = () => {
      document.addEventListener("scroll", () => {
        requestAnimationFrame(() => {
          calculateScrollDistance();
        })
      })
    }
    listenToScrollEvent();
  }, [scrollPositionPercentage, calculateScrollDistance]);

  return [scrollPositionPercentage] as const
}

export default useProgressBar;
