import React from 'react'

export default function Phone() {
  return (
    <svg width="31px" height="30px" viewBox="0 0 31 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Phone-icon</title>
      <defs>
        <polygon id="path-1" points="0 0.0425744681 29.9573617 0.0425744681 29.9573617 30 0 30"></polygon>
      </defs>
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Fat-footer-" transform="translate(-1019.000000, -190.000000)">
          <g id="Footer" transform="translate(-0.500000, 147.000000)">
            <g id="Phone-icon" transform="translate(1020.000000, 43.000000)">
              <g id="Group-3">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <g id="Clip-2"></g>
                <path d="M28.3962766,19.587766 L19.5445532,28.4394894 C19.289234,28.6961489 18.8391064,28.6967872 18.5824468,28.4388511 L17.3617021,27.2181064 L27.1755319,17.4042766 L28.3962766,18.6250213 L28.3969149,18.6256596 C28.5252128,18.7533191 28.5957447,18.9241915 28.5957447,19.1063617 C28.5957447,19.2885319 28.5252766,19.4594681 28.3962766,19.587766 L28.3962766,19.587766 Z M1.56051064,11.4169149 C1.43221277,11.2892553 1.36168085,11.118383 1.36168085,10.9362128 C1.36168085,10.7540426 1.43214894,10.5831702 1.56114894,10.4548085 L10.4128723,1.60308511 C10.5405319,1.47478723 10.7114043,1.40425532 10.8935745,1.40425532 C11.0757447,1.40425532 11.246617,1.4747234 11.3749787,1.6037234 L26.2127234,16.4414681 L16.3988936,26.2552979 L1.56051064,11.4169149 Z M29.358383,17.6615745 L12.3384255,0.641617021 C11.9536596,0.255510638 11.4238723,0.0425743389 10.8942128,0.0425743389 C10.3640426,0.0425743389 9.83393617,0.25512766 9.44942553,0.640978723 L0.599042553,9.4913617 C0.212744681,9.87631915 0,10.3896383 0,10.9362128 C0,11.4827872 0.212744681,11.9960426 0.598404255,12.3803617 L17.619,29.4009574 C18.0040213,29.7872553 18.5172766,30 19.0637872,30 C19.6103617,30 20.123617,29.7872553 20.5079362,29.4015957 L29.3583191,20.5512128 C29.744617,20.1662553 29.9573617,19.6529362 29.9573617,19.1064255 C29.9573617,18.5599149 29.7446809,18.0465319 29.358383,17.6615745 L29.358383,17.6615745 Z" id="Fill-1" fill="#4CC2B8" mask="url(#mask-2)"></path>
              </g>
              <path d="M23.7597318,22.4740264 L22.4740627,23.7597557 C22.2229315,24.0108987 22.2229315,24.4177179 22.4740627,24.6688007 C22.5995982,24.794342 22.764066,24.8571429 22.928594,24.8571429 C23.0930618,24.8571429 23.2575296,24.794342 23.3831253,24.6688007 L24.6687944,23.3830713 C24.9199257,23.1319283 24.9199257,22.7251091 24.6687944,22.4740264 C24.4176029,22.2229436 24.0108028,22.2229436 23.7597318,22.4740264" id="Fill-4" fill="#4CC2B8"></path>
              <path d="M6.61690922,5.33115782 L5.33121,6.6168608 C5.08007286,6.86799868 5.08007286,7.2748095 5.33121,7.52588711 C5.45674844,7.65148618 5.62122006,7.71428571 5.78569169,7.71428571 C5.95016331,7.71428571 6.11463493,7.65148618 6.24023364,7.52594737 L7.52593286,6.24024439 C7.77707,5.98910651 7.77707,5.58229569 7.52593286,5.33121809 C7.27479572,5.08008021 6.86798609,5.08008021 6.61690922,5.33115782" id="Fill-6" fill="#4CC2B8"></path>
              <path d="M10.7142857,2.57142857 C10.9509703,2.57142857 11.1428571,2.76331543 11.1428571,3 C11.1428571,3.23668457 10.9509703,3.42857143 10.7142857,3.42857143 C10.4776011,3.42857143 10.2857143,3.23668457 10.2857143,3 C10.2857143,2.76331543 10.4776011,2.57142857 10.7142857,2.57142857" id="Fill-8" fill="#4CC2B8"></path>
              <path d="M9.90821967,26.1354224 C8.77909128,25.5709135 7.76398911,24.8416334 6.89010798,23.9671629 C6.01558697,23.0933323 5.28638222,22.0782144 4.72188201,20.9490046 C4.5532742,20.6111439 4.14266461,20.4751678 3.80608888,20.6437782 C3.46880928,20.8123246 3.33219537,21.2223646 3.50080317,21.5595855 C4.13133877,22.8200362 4.94654311,23.9545571 5.92498027,24.9323055 C6.90277756,25.9108218 8.03721694,26.7259747 9.29764826,27.3565841 C9.39561355,27.4052155 9.4995937,27.4285714 9.6022301,27.4285714 C9.85287024,27.4285714 10.0934643,27.2906116 10.2134414,27.0512936 C10.3821132,26.7139448 10.2454993,26.3040968 9.90821967,26.1354224" id="Fill-10" fill="#4CC2B8"></path>
              <path d="M8.21090717,27.9077462 C6.87977429,27.2424279 5.68168746,26.3816578 4.64970959,25.3503106 C3.61840398,24.3184133 2.7577298,23.1202169 2.09243837,21.7890303 C1.93072965,21.4675555 1.5385585,21.3363987 1.21770787,21.4974417 C0.895573829,21.6584846 0.765094571,22.0499993 0.926131032,22.3722075 C1.65443141,23.828806 2.59724336,25.1409242 3.72785988,26.2721975 C4.85908755,27.4028597 6.17115274,28.3457709 7.62769239,29.0741007 C7.72125855,29.1205495 7.82056946,29.1428571 7.91859697,29.1428571 C8.1579821,29.1428571 8.38777227,29.0110892 8.50236178,28.782512 C8.66352047,28.460365 8.53304121,28.0687892 8.21090717,27.9077462" id="Fill-12" fill="#4CC2B8"></path>
              <path d="M21.789158,2.09201851 C23.1202902,2.75731153 24.3183763,3.61812797 25.3503536,4.64953079 C26.3816587,5.68154482 27.2423324,6.8798057 27.9076234,8.21106406 C28.0222129,8.43965349 28.2520029,8.57142857 28.4913879,8.57142857 C28.5894154,8.57142857 28.6887262,8.54911971 28.7822923,8.50266838 C29.1044262,8.34161674 29.2349054,7.95001983 29.073869,7.62785543 C28.345569,6.17117848 27.4027576,4.85898961 26.2721417,3.72759419 C25.1409147,2.59687109 23.8288502,1.65397027 22.3722503,0.925601237 C22.0501775,0.765772 21.6586177,0.895041154 21.4975203,1.21720556 C21.336545,1.53943108 21.4670242,1.93096687 21.789158,2.09201851" id="Fill-14" fill="#4CC2B8"></path>
              <path d="M20.9489323,4.7212178 C22.0780543,5.28587199 23.0931506,6.01519298 23.9670267,6.8897894 C24.8415427,7.76374583 25.5707432,8.77907381 26.1353042,9.90838219 C26.2552806,10.1477346 26.4958733,10.2857143 26.746448,10.2857143 C26.8490838,10.2857143 26.9530633,10.262355 27.0510281,10.2137166 C27.3883057,10.0450819 27.5249828,9.63504681 27.356312,9.29771345 C26.72578,8.03708121 25.9105804,6.902461 24.9321489,5.92450784 C23.9543572,4.94585071 22.8199243,4.13058043 21.5595003,3.49994432 C21.2222226,3.33329358 20.8123192,3.46794533 20.6436484,3.80527869 C20.4750416,4.14254805 20.6117187,4.55251912 20.9489323,4.7212178" id="Fill-16" fill="#4CC2B8"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
