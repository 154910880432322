import React, { useState, useEffect } from 'react'
import Link from '../../atoms/Link'

import Phone from '../../atoms/svg/Phone'
// import Facebook from '../../atoms/svg/Facebook'
import Linkedin from '../../atoms/svg/Linkedin'
// import Twitter from '../../atoms/svg/Twitter'
import Clock from '../../atoms/svg/Clock'
import Email from '../../atoms/svg/Email'
import Youtube from '../../atoms/svg/Youtube'
import MaxWidth from '../../molecules/Maxwidth'
import { useGlobalStateContext } from '../../../Context/app'
import { replaceLinks } from '../../../utils/service'

import { FooterItem } from '../../../types'

type Menu = FooterItem[]

const Footer = () => {
  const year = new Date().getFullYear()
  const { pageContext } = useGlobalStateContext()  
  
  
  const [menus, setMenu] = useState<Menu>()
  
  useEffect(() => {
    if (pageContext)
     {
      setMenu(pageContext?.navigation?.footerItems);
    }
  }, [pageContext])

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  return (
    <footer className="bg-blue-stone text-white font-light">
      <MaxWidth>
        <div className={`mx-5 grid md:grid-cols-2 ${menus?.length === 3 ? 'lg:grid-cols-4' : 'lg:grid-cols-5'} gap-10 py-10 text-sm`}>
          {menus?.map((menu, index) => (
            <div key={index}>
              <p className="leading-9 font-medium">{menu.name}</p>
              <ul>
                {menu.items.map((submenu, index) => (
                  <li className="py-1" key={index}><Link to={submenu.navanchor ? `${replaceLinks(`/${submenu.link.slug.current}`)}#${submenu.navanchor}` : replaceLinks(`/${submenu.link.slug.current}`)}>{submenu.name}</Link></li>
                ))}
              </ul>
            </div>
          ))}
          <div>
            <Link type="external" to="tel:+27113001100" className="pb-3 flex items-center"><span className="pr-2"><Phone /></span> +27 11 300 1100</Link>
            <Link type="external" to="mailto:info@cardinal.co.za" target="_blank" className="py-3 flex items-center"><span className="pr-2"><Email /></span> info@cardinal.co.za</Link>
            <p className="py-3 flex items-center"><span className="pr-2"><Clock /></span> Mon-Fri: 8am-5pm</p>
            <div className="flex py-3 items-center">
              <Link type="external" to="https://www.linkedin.com/company/cardinal-insurance-management-systems/" target="_blank" className="pr-2"><Linkedin /></Link>
              {/* <Link type="external" to="https://facebook.com/CadinalCims" target="_blank" className="pr-2"><Facebook /></Link> */}
              <Link type="external" to="https://www.youtube.com/channel/UC-Wlnv71qF7beNtGaCnDfqQ" target="_blank" className="pr-2"><Youtube /></Link>
            </div>
            <p className="py-3">ISO/IEC 27001:2013 Certified</p>
          </div>
        </div>
      </MaxWidth>
      <div className="bg-viking text-sm">
        <MaxWidth className='mx-5 lg:mx-auto py-5 md:grid grid-cols-2'>
          <p>© CARDINAL (Pty) Ltd {year} All Rights Reserved.</p>
          <p><Link to="/privacy-policy">Privacy Policy</Link>  |  <Link to="/terms-and-conditions">T's &amp; C's</Link></p>
        </MaxWidth>
      </div>
    </footer>
  )
}

export default Footer